import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/video-streaming-developer-docs/video-streaming-developer-docs/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import GQLCodeSnippet, { GQLOpen, GQLClose, GQLLine, GQLTab, GQLName, GQLKeyword, GQLAttribute, GQLOperator, GQLComment, GQLLink } from "../../components/GQLCodeSnippet/GQLCodeSnippet";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`ECDN server’s hardware configuration.`}</p>
    <GQLCodeSnippet mdxType="GQLCodeSnippet">
      <GQLLine mdxType="GQLLine"><GQLKeyword mdxType="GQLKeyword">type</GQLKeyword> Hardware <GQLOpen mdxType="GQLOpen" /></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Number of CPU cores in server.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Nullable in case of errors.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">cpuCores</GQLName>: <GQLLink href="/ecdn-api-scalars/Int" mdxType="GQLLink">Int</GQLLink></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Server's total memory in bytes.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Nullable in case of errors.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">memory</GQLName>: <GQLLink href="/ecdn-api-scalars/Byte" mdxType="GQLLink">Byte</GQLLink></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Server's total cache memory in bytes.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Nullable in case of errors.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">cacheMemory</GQLName>: <GQLLink href="/ecdn-api-scalars/Byte" mdxType="GQLLink">Byte</GQLLink></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Server's system disk size in bytes.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Nullable in case of errors.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">systemDiskSize</GQLName>: <GQLLink href="/ecdn-api-scalars/Byte" mdxType="GQLLink">Byte</GQLLink></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Status of cache disk.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">cacheDiskStatus</GQLName>: <GQLLink href="/ecdn-api-enums/CacheDiskStatus" mdxType="GQLLink">CacheDiskStatus</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Server's cache disk size in bytes. Null if cache disk isn't attached to server.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">cacheDiskSize</GQLName>: <GQLLink href="/ecdn-api-scalars/Byte" mdxType="GQLLink">Byte</GQLLink></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># The hypervisor's type running this server. Null if unknown.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># (never checked in before or its version is too old)</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">hypervisor</GQLName>: <GQLLink href="/ecdn-api-enums/Hypervisor" mdxType="GQLLink">Hypervisor</GQLLink></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
      <GQLLine mdxType="GQLLine"><GQLClose mdxType="GQLClose" /></GQLLine>
    </GQLCodeSnippet>
    <h2>{`Fields`}</h2>
    <h3>{`cpuCores: `}<a parentName="h3" {...{
        "href": "/ecdn-api-scalars/Int"
      }}>{`Int`}</a></h3>
    <p>{`Number of CPU cores in server.
Nullable in case of errors.`}</p>
    <h3>{`memory: `}<a parentName="h3" {...{
        "href": "/ecdn-api-scalars/Byte"
      }}>{`Byte`}</a></h3>
    <p>{`Server’s total memory in bytes.
Nullable in case of errors.`}</p>
    <h3>{`cacheMemory: `}<a parentName="h3" {...{
        "href": "/ecdn-api-scalars/Byte"
      }}>{`Byte`}</a></h3>
    <p>{`Server’s total cache memory in bytes.
Nullable in case of errors.`}</p>
    <h3>{`systemDiskSize: `}<a parentName="h3" {...{
        "href": "/ecdn-api-scalars/Byte"
      }}>{`Byte`}</a></h3>
    <p>{`Server’s system disk size in bytes.
Nullable in case of errors.`}</p>
    <h3>{`cacheDiskStatus: `}<a parentName="h3" {...{
        "href": "/ecdn-api-enums/CacheDiskStatus"
      }}>{`CacheDiskStatus`}</a>{`!`}</h3>
    <p>{`Status of cache disk.`}</p>
    <h3>{`cacheDiskSize: `}<a parentName="h3" {...{
        "href": "/ecdn-api-scalars/Byte"
      }}>{`Byte`}</a></h3>
    <p>{`Server’s cache disk size in bytes. Null if cache disk isn’t attached to server.`}</p>
    <h3>{`hypervisor: `}<a parentName="h3" {...{
        "href": "/ecdn-api-enums/Hypervisor"
      }}>{`Hypervisor`}</a></h3>
    <p>{`The hypervisor’s type running this server. Null if unknown.
(never checked in before or its version is too old)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      